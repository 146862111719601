<template>
  <v-dialog v-model="cashDialog" max-width="500" persistent>
    <v-card>
      <v-card-title>cash payment</v-card-title>
      <v-divider></v-divider>
      <v-card-text style="padding-top: 20px">
        <p>
          If the client opted to pay for your services by cash, Please do
          confirm that the service has been fully paid, otherwise cancel.
        </p>

        <v-switch
            v-model="payNow"
            :label="`Confirm Cash Payment: ${payNow}`"
        ></v-switch>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="blue" small text @click="submit" v-on="on">
              confirm
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import Restful from "@/services/RestFul";
import Utils from "../../mixins/utils.js";
//   import moment from "moment";

export default {
  props: {
    cashDialog: Boolean,
    myId: {type: Number},
  },
  mixins: [Utils],
  data() {
    return {
      pay: {
        payment_method: null,
        mobile_phone: null,
      },
      explainMethod: "",
      payMethodList: [],
      create: {
        service: null,
        description: null,
      },
      details: {},
      payDisplay: "",
      payNow: false,
    };
  },
  watch: {},
  created() {
    // this.find()
  },
  methods: {
    // find() {
    //   this.loading = true;
    //   let self = this;
    //   let id = this.myId;
    //   let data = { id };
    //   console.log(data);
    //   Restful.subscribers.available
    //     .availableTimeDetail(data)
    //     .then((response) => {
    //       console.log(response);
    //       this.details = response.data;
    //       this.loading = false;
    //     })
    //     .catch((error) => {
    //       console.log(error.response);
    //       self.snack.color = "error";
    //       self.snack.text = error.response.data;
    //       self.loading = false;
    //     });
    // },
    closeDialog() {
      let payload = {id: this.myId, state: false};
      this.$emit("closePay", payload);
    },
    submit() {
      let paid = this.payNow;
      let data = {paid};
      let payload = {id: this.myId, state: false, data: data};
      console.log("payload", payload);
      this.$emit("submitPay", payload);
    },
  },
};
</script>
